import { RFValue } from "react-native-responsive-fontsize";

export default {
  COLORS: {
    ALERT: "#ec1414",
    ICON: '#E88A66', //COR DOS CADEADOS E DAS SETAS PARA O LADO
    ICON_HEADER_PURCHASE: "#2e2e2e", //COR DAS SETINHAS 
    BACKGROUND_HEADER: "none", //COR DE TODOS OS CABEÇALHOS
    //TELA CLICKCOURSE, PLANS e SIGNUP
    ICON_HEADER: "#B46747",  //COR DA PALAVRA ESCOLHER COMPRA, CADASTRAR-SE E COMPLETAR PAGAMENTO e VOLTAR da EDITPROFILE

    //CORES GERAIS
    PRIMARY_900: "#E88A66", //COR MAIS ESCURA DO CLIENTE = COR PRINCIPAL DOS DETALHES DO APP - CHECKBOX, FECHAR TERMOS DE USO, ETC 
    PRIMARY_800: "#6F3D2B", //COR INTERMEDIÁRIA DO CLIENTE PARA INPUT LOGIN E ACTIVITY INDICATOR
    PRIMARY_700: "#a7a4c5", //COR MAIS CLARA DO CLIENTE = COR PRINCIPAL MAIS CLARA PARA INPUT LOGIN E ACTIVITY INDICATOR
    TEXT_BUTTON: "#e9dbd6", //USADA PARA O TEXTO DOS BOTÕES LOGIN, CLICKCOURSE, SIGNUP - COR DE FUNDO É A PRIMARY_900

    //CORES DO DRAWER
    DRAWER_SELECTED: "#a7a4c5", //COR DRAWER CLICADO E DO TEXTO DO DRAWER

    //TELAS: LOGIN, CLICKCOURSE, SIGNUP E PLANS
    BACKGROUND_ABOUT: "#E8DBD6", //COR PARA A ABOUT, DRAWER, CLICK COURSE, PLANS, EDITPROFILE, LOGIN E CADASTRO
    TITLE_ABOUT: '#D09F7F', //TAMANHOS EXTRABIG, BIG E STANDARD (usadas em páginas como dos termos de uso)
    TEXT_ABOUT: '#6F3D2B', //TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //TELA TERMOS DE USO
    BACKGROUND_TERMS: "#FFFFFF", //COR PARA O FUNDO DOS TERMOS
    
    //TELA MAIN
    ICON_HEADER_CLICKCLASS: "#6F3D2B", //COR DO ÍCONE VOLTAR DA CLICKCLASS
    ICON_HEADER_MAIN: "#E9DBD6", //COR DO ICONE DRAWER
    BACKGROUND_MAIN: "#E9DBD6", //COR PARA A MAIN E CLICKCLASS
    TITLE_OVERPHOTO_MAIN: "#E9DBD6", //USADA PARA O TÍTULO E SUBTÍTULO DA MAIN
    TITLE_MAIN: "#6F3D2B", //USADA PARA PARA OS TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_MAIN: "#6F3D2B", //USADA PARA PARA OS TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //FOOTER TELA ABOUT E DRAWER
    BACKGROUND_FOOTER: "#a7a4c5", //COR DO RODAPÉ
    TEXT_FOOTER: "#E9DBD6", //USADA PARA O TEXTO DO RODAPÉ

    GRADIENT_MAIN: [
      "",
      "",
      "",
      "",
    ],

    //BOTÕES ARQUIVAR E PUBLICAR
    BACKGROUND_PUBLISH: "#a7a4c5", //COR DO RODAPÉ
    BACKGROUND_ARCHIVE: "#e88a66", //COR DO RODA
    TEXT_PUBLISH: "#e9dbd6", //USADA PARA O TEXTO DO RODAPÉ
    TEXT_ARCHIVE: "#efccc5", //USADA PARA O TEXTO DO RODAPÉ
  },

  FONTFAMILY: {
    BOLD: "text_Bold",
    MEDIUM: "text_Medium",
    REGULAR: "text_Regular",
    LIGHT: "text_Light",
    TITLE: "text_Title",
    SUBTITLE: "text_Subtitle",
    TEXTS: "text_Texts"
  },
  FONTSIZE: {
    EXTRABIG: RFValue(30, 980), //USADA 1X NA COURSES
    BIG: 26,
    STANDARD: 20,
    MEDIUM: 18,
    SMALL: 15,
    EXTRASMALL: 13,
  },
};
